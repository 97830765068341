import React from "react";

import {
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  Checkbox,
  withStyles,
} from "@material-ui/core";

const StyledTableHead = withStyles((theme) => ({
  root: {
    position: "sticky",
    backgroundColor: "#3D5BA9",

    top: 0,
    maxHeight: "100px!important",
    // alignSelf: "flex-start",
    zIndex: 1,
  },
}))(TableHead);

const HeaderRow = withStyles((theme) => ({
  root: {
    maxHeight: "100px!important",
    backgroundColor: "#3D5BA9",
  },
}))(TableRow);

const HeaderCell = withStyles((theme) => ({
  root: {
    padding: "12px",
    paddingCheckbox: {
      padding: "0px",
    },
    backgroundColor: "#3D5BA9",
    color: theme.palette.common.white,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipses",
  },
  paddingCheckbox: {
    padding: "0px",
    backgroundColor: "red",
  },
}))(TableCell);

const BodyRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFA",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
}))(TableRow);

const BodyCell = withStyles((theme) => ({
  root: {
    padding: "12px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipses",
    borderRight: "1px solid rgba(0, 0, 0, 0.02)",
  },
  sizeSmall: {
    padding: "6px",
  },
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
  icon: {
    "& path": {
      fill: "#eee",
    },
  },
}))(TableSortLabel);

const StyledCheckbox = withStyles((theme) => ({
  root: {
    padding: "0px",
  },
}))(Checkbox);

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <StyledCheckbox
          padding={"none"}
          size={"small"}
          color={"default"}
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

export {
  StyledTableHead,
  HeaderRow,
  HeaderCell,
  BodyRow,
  BodyCell,
  StyledTableSortLabel,
  StyledCheckbox,
  IndeterminateCheckbox,
};
