import { makeStyles, lighten } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    border: "rgb(50, 53, 93, 0.2) 2px solid",
    borderRadius: "4px",
    minHeight: "65vh",
    maxHeight: "65vh",
    backgroundColor: "#FAFAFA",
  },
  tableHead: {
    position: "sticky",
    top: 0,
    maxHeight: "100px!important",
    // alignSelf: "flex-start",
    zIndex: 1,
  },
  headerCheckbox: {
    color: lighten(theme.palette.primary.main, 0.85),
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "25px",
  },
  resizer: {
    cursor: "col-resize",
    display: "inline-block",
    background: "rgba(0, 0, 0, 0.05)",
    width: "20px",
    height: "100%",
    position: "absolute",
    right: 0,
    top: 0,
    transform: "translateX(50%)",
    zIndex: 1,
    borderStyle: "hidden",
    "&:focus": {
      outline: "none!important",
    },
  },
  paginationToolbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px",
  },
  paginationSpacer: {
    display: "none",
  },
  paginationCaption: {
    "&:first-of-type": {
      display: "none",
    },
  },
  paginationSelect: {
    "&::before": {
      content: '"Rows per page:  "',
    },
  },
}));
