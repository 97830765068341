import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import MainPage from "./MainPage";

const csm = "8e10abe9-7dcd-45b5-9128-4c8a301fd1c2"; // GER
const apiUrl = "https://api-lab.locatorx.com/lx-atlas/api/";

export default class App extends React.Component {
  render() {
    const NoAuthRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) => <Component {...props} apiUrl={apiUrl} csm={csm} />}
      />
    );

    return (
      <Router>
        <div>
          <NoAuthRoute exact path="/" component={MainPage} />
        </div>
      </Router>
    );
  }
}
