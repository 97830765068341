import { makeStyles } from "@material-ui/core/styles";
import createClass from "create-react-class";
import Select, { components } from "react-select";

const useStyles = makeStyles((theme: any) => ({
  label: {
    fontWeight: 500,
  },
}));

export default function ReactSelectCheckboxes(props: any) {
  const classes = useStyles();
  const {
    isDisabled,
    options,
    onChange,
    placeholder = "Select",
    styles,
    value,
  } = props;
  const Option = createClass({
    render() {
      return (
        <div>
          <components.Option {...this.props}>
            <input
              type="checkbox"
              checked={this.props.isSelected}
              onChange={(e) => null}
            />{" "}
            <label className={classes.label}>{this.props.label} </label>
          </components.Option>
        </div>
      );
    },
  });

  return (
    // Taken from https://codesandbox.io/s/o567zv6j2q?file=/example.js:1576-1589
    <Select
      backspaceRemovesValue={false}
      closeMenuOnSelect={false}
      components={{ Option, MultiValue }}
      hideSelectedOptions={false}
      isDisabled={isDisabled}
      isMulti
      menuPortalTarget={document.querySelector("body")}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
      options={options}
      styles={{
        ...styles,
        menuPortal: (styles:any) => ({ ...styles, zIndex: 99999 }),
      }}
      value={value}
    />
  );
}

const MultiValue = (props: any) => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};
