import React, { useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {retrieveZoneRadioIds} from "../api"
import ReactSelectCheckboxes from "../../../components/Selects/ReactSelectCheckboxes";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../../../components/Loading/Loading";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme: any) => ({
  button: {
    minWidth: "8rem",
  },
  clearFiltersButton: {
    backgroundColor: "#6D6E70",
    color: "white",
  },
  filterButton: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: "white",
    marginLeft: "1rem !important",
    "&:hover": {
      backgroundColor: "#5884F5",
      color: "#fff",
    },
  },
  filter: {
    padding: ".5rem 0 !important",
  },
  filterOption: {
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: ".5rem",
    marginTop:"1vh",
    fontWeight:700,
    fontFamily:"Raleway, sans-serif",
    fontSize:"0.875rem"
  },
  field: {
    paddingTop: ".5rem",
  },
  root: {
    flexGrow: 1,
    height: "100%",
  },
}));

const styles: any = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function FilterMenu(props: any) {
  const { setState, state, parentFilters, setParentFilters, zoneRadioNodeOptions = {} } = props;
  const { radios = [] } = zoneRadioNodeOptions;
  const [filterState, setFilterState] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState<any>({
    node: {},
    radio: {},
  });
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFilterState({ ...parentFilters });
    setOpen(false);
  };

  // We set the initial filters using useEffect for the instance that a user does not
  // navigate away from the page, but uses the "Tag Look up" bar to go to another snapshot page
  useEffect(() => {
    const node: any = {};
    const radio: any = {};

    retrieveZoneRadioIds({...props}).then((response)=>{
      response.radios.forEach((item: any) => {
        Object.keys(item).forEach((element) => {
          if (element === "node" && !node[item.node]) {
            node[item.node] = { label: item.node, value: item.node };
          }
  
          if (element === "radio" && !radio[item.radio]) {
            radio[item.radio] = { label: item.radio, value: item.radio };
          }
          return null;
        });
      });  
    })

    setFilterOptions({ node, radio });
    setFilterState({ ...parentFilters });
  }, [parentFilters]);

  return (
    <>
      <span onClick={handleClickOpen} style={{ cursor: "pointer" }}>
        <div className="exportIcon">
          {`Filters`}
          <i className="fas fa-filter ml-2"></i>
        </div>
      </span>
      <Dialog
        aria-labelledby="filters"
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="filters" onClose={handleClose}>
          Filters
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {/* Nodes */}
            <Grid className={classes.filter} item xs={12}>
              <div className={classes.filterOption}>
                <label>Node</label>
              </div>
              <ReactSelectCheckboxes
                className={classes.field}
                options={
                  filterOptions
                    ? Object.keys(filterOptions.node).map((item) => {
                        return {
                          label: item,
                          value: item,
                        };
                      })
                    : []
                }
                onChange={(e: any) => {
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    node: e,
                  }));
                }}
                value={filterState.node}
              />
            </Grid>

            {/* Radio */}
            <Grid className={classes.filter} item xs={12}>
              <div className={classes.filterOption}>
                <label>Radio</label>
              </div>
              <ReactSelectCheckboxes
                options={
                  filterOptions
                    ? Object.keys(filterOptions.radio).map((item) => {
                        return {
                          label: item,
                          value: item,
                        };
                      })
                    : []
                }
                onChange={(e: any) => {
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    radio: e,
                  }));
                }}
                value={filterState.radio}
              />
            </Grid>

            {/* Start Date */}
            <Grid className={classes.filter} item xs={6}>
              <div className={classes.filterOption}>
                <label>Select Start Date</label>
              </div>
              <DatePicker
                className="custom-select no-select-arrows"
                dateFormat="yyyy/MM/dd"
                placeholderText="Start date"
                onChange={(date: any) => {
                  if (filterState.endDate && date > filterState.endDate) {
                    alert("Can't set start date later than end date");
                    return;
                  }
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    startDate: date,
                  }));
                }}
                popperModifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                  },
                }}
                popperPlacement="bottom-start"
                selected={filterState.startDate}
              />
            </Grid>

            {/* End Date */}
            <Grid className={classes.filter} item xs={6}>
              {" "}
              <div className={classes.filterOption}>
                <label>Select End Date</label>
              </div>
              <DatePicker
                className="custom-select no-select-arrows"
                dateFormat="yyyy/MM/dd"
                placeholderText="End date"
                onChange={(date: any) => {
                  if (filterState.startDate && date < filterState.startDate) {
                    alert("Can't set end date earlier than start date");
                    return;
                  }
                  setFilterState((prevState: any) => ({
                    ...prevState,
                    endDate: date,
                  }));
                }}
                popperModifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                  },
                }}
                popperPlacement="bottom-start"
                selected={filterState.endDate}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <Loading color="#5884F5" opaque /> : ""}

          <Button
            className={`${classes.clearFiltersButton} ${classes.button}`}
            onClick={() => {
              setFilterState({
                count: 0,
                endDate: null,
                limit: 25,
                node: null,
                page: 0,
                radio: null,
                sorted: [{ id: "time_of_report", desc: true }],
                start: 0,
                startDate: null,
              });
            }}
            variant="contained"
          >
            Clear Filters
          </Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            className={classes.button}
            onClick={() => {
              handleClose();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={`${classes.filterButton} ${classes.button}`}
            onClick={() => {
              setState((prevState: any) => ({
                ...prevState,
                filters: {
                  start: "0",
                },
              }));
              setParentFilters(filterState)
              setLoading(false);
              setOpen(false);
            }}
            variant="contained"
          >
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
