import React from "react";

import EnhancedTable from "./components/EnhancedTable";
import { ControlledTableInterface } from "./interface";
const ControlledTable = (props: ControlledTableInterface) => {
  const columns = React.useMemo(() => props.columns, [props.columns]);
  const data = React.useMemo(() => props.data, [props.data]);
  return (
    <EnhancedTable
      // data, columns, state, setState, saveFilters function
      columns={columns}
      data={data}
      saveFilterSettings={props.saveFilterSettings}
      setState={props.setState}
      state={props.state}
      // controlled props
      controlledCount={props.controlledCount || 0}
      controlledPageCount={props.controlledPageCount || 0}
      controlledPageIndex={props.controlledPageIndex || 0}
      controlledPageSize={props.controlledPageSize || 0}
      defaultSort={props.defaultSort}
      // select checkboxes
      clearSelected={props.clearSelected}
      isSelect={props.isSelect}
      onSelect={props.onSelect}
      selectAll={props.selectAll}
      // other
      defaultColumnOrder={props.defaultColumnOrder}
      globalFilterLabel={props.globalFilterLabel}
      language={props.language || "en"}
      onColumnDrop={props.onColumnDrop}
      rowOptions={props.rowOptions}
      stickyHeader={props.stickyHeader}
      tableHeader={props.tableHeader}
      toolbarChildren={props.toolbarChildren}
    />
  );
};

export default ControlledTable;
